import React from 'react';
import { useForm } from 'react-hook-form';

import ErrorMessage from 'components/ErrorMessage/ErrorMessage';

import {
  container,
  inputContainer,
  label,
  input,
  area,
} from './ContactForm.module.scss';

const ContactForm = ({ onSubmit, actions }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  return (
    <form className={container} noValidate onSubmit={handleSubmit(onSubmit)}>
      <div className={inputContainer}>
        <label className={label} htmlFor="name">
          Ονοματεπώνυμο
        </label>
        <input
          className={input}
          id="name"
          {...register('name', {
            required: 'Το επώνυμο είναι υποχρεωτικό',
          })}
          type="text"
          aria-label="Επώνυμο"
        />
        {errors.name && <ErrorMessage>{errors.name.message}</ErrorMessage>}
      </div>
      <div className={inputContainer}>
        <label className={label} htmlFor="email">
          Email
        </label>
        <input
          {...register('email', {
            required: 'Το email είναι υποχρεωτικό',
            pattern: {
              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              message: 'Λάθος μορφή email',
            },
          })}
          type="email"
          aria-label="Email"
          className={input}
        />
        {errors.email && <ErrorMessage>{errors.email.message}</ErrorMessage>}
      </div>
      <div style={{ marginBottom: '2.5rem' }}>
        <div className={inputContainer} style={{ flexBasis: '100%' }}>
          <label className={label} htmlFor="comments">
            Mήνυμα
          </label>
          <textarea
            className={area}
            id="message"
            {...register('message', {
              required: 'Το μήνυμα είναι υποχρεωτικό',
            })}
            rows="8"
            cols="40"
          />
          {errors.message && (
            <ErrorMessage>{errors.message.message}</ErrorMessage>
          )}
        </div>
      </div>
      {actions}
    </form>
  );
};

export default ContactForm;
