import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { FiMail, FiMapPin, FiPhone } from 'react-icons/fi';
import { IoLogoFacebook, IoLogoInstagram } from 'react-icons/io5';

import ActionsContainer from 'components/ActionsContainer/ActionsContainer';
import AuthWidget from 'components/AuthWidget/AuthWidget';
import LoadingPrimaryButton from 'components/Buttons/LoadingPrimaryButton';
import ContactForm from 'components/ContactForm/ContactForm';
import Container from 'components/Container/Container';
import { logo, socialContainer } from 'components/Footer/Widgets.module.scss';
import Hero from 'components/Hero/Hero';
import Layout from 'components/Layout';
import Seo from 'components/Seo';

import useRequest from 'hooks/useRequest';

import {
  errorMessage,
  successMessage,
} from 'styles/components/form.module.scss';
import { banner } from 'styles/pages/common.module.scss';
import {
  container,
  title,
  formContainer,
  btn,
  column,
  link,
  icon,
} from 'styles/pages/epikoinonia.module.scss';

const query = graphql`
  query Contact {
    site {
      siteMetadata {
        title
        brand
        type
        url
        tel
        email
        address
        city
        postalCode
        country
        lat
        long
        fullAddress
        fb
        instagram
      }
    }
  }
`;

const EpikoinoniaPage = () => {
  const {
    site: { siteMetadata: info },
  } = useStaticQuery(query);
  const { data, loading, error, fetch } = useRequest();
  const submit = (data) => {
    fetch(`/contacts`, { method: 'post', data });
  };

  return (
    <Layout fullWidth>
      <Seo title="Επικοινωνία">
        <script type="application/ld+json">
          {`
              {
                "@context": "https://schema.org",
                "@type": "${info.type}",
                "@id":  "${info.url}",
                "url": "${info.url}",
                "name": "${info.brand}",
                "telephone": "${info.tel}",
                "image": "${info.url}logo.svg",
                "priceRange": "€",
                "address": {
                  "@type": "PostalAddress",
                  "streetAddress": "${info.address}",
                  "addressLocality": "${info.city}",
                  "postalCode": "${info.postalCode}",
                  "addressCountry": "${info.country}"
                },
                "geo": {
                  "@type": "GeoCoordinates",
                  "latitude": ${info.lat},
                  "longitude": ${info.long}
                }
              }
            `}
        </script>
      </Seo>
      <Hero title="ΕΠΙΚΟΙΝΩΝΙΑ" containerClass={banner} />
      <Container>
        <div className={container}>
          <AuthWidget
            containerClass={formContainer}
            title={<h2 className={title}>Επικοινωνήστε μαζί μας</h2>}
            form={
              <ContactForm
                onSubmit={submit}
                actions={
                  <ActionsContainer>
                    <LoadingPrimaryButton
                      buttonClass={btn}
                      type="submit"
                      loading={loading}
                    >
                      ΥΠΟΒΟΛΗ
                    </LoadingPrimaryButton>
                  </ActionsContainer>
                }
              />
            }
          >
            {error && (
              <p className={errorMessage}>
                Συνέβη κάποιο σφάλμα. Προσπαθήστε ξανά!
              </p>
            )}
            {data && data.name && (
              <p className={successMessage}>
                Η αποστολή ολοκληρώθηκε. Θα επικοινωνήσουμε μαζί σας σύντομα.
              </p>
            )}
          </AuthWidget>
          <div className={column}>
            <iframe
              title="Διεύθυνση επιχείρησης"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12610.776771672217!2d21.3505795!3d37.7972059!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc9cdb16f6b43e473!2sNicolas%20jewelry%20accesories!5e0!3m2!1sel!2sgr!4v1619533704425!5m2!1sel!2sgr"
              width="100%"
              height="450"
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
            ></iframe>
            <p>
              <FiMapPin className={icon} /> {info.fullAddress}
            </p>
            <p>
              <FiPhone className={icon} /> {info.tel}
            </p>
            <p>
              <FiMail className={icon} />
              <a className={link} href={`mailto:${info.email}`}>
                {info.email}
              </a>
            </p>
            <div className={socialContainer}>
              <a
                href={info.fb}
                target="_blank"
                rel="noreferrer"
                className={logo}
                aria-label={`${info.title} facebook page`}
              >
                <IoLogoFacebook size="2rem" />
              </a>
              <a
                href={info.instagram}
                target="_blank"
                rel="noreferrer"
                className={logo}
                aria-label={`${info.title} instagram account`}
              >
                <IoLogoInstagram size="2rem" />
              </a>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default EpikoinoniaPage;
